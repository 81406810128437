import React from 'react'

export default function StartJourneySec() {
  return (
    <>
        <div className="start-journey-sec">
        <div className="container">
            <div className="start-journey-box">
                <h2>Start Journey. Enroll Now!</h2>
                <p>Lorem ipsum dolor sit amet, conse ctetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                </p>
                <a href="#">Join for free</a>
            </div>
        </div>
    </div>
    </>
  )
}
